import React, { useState } from "react"
import parse from "html-react-parser"
import {
  SectionHeading,
} from "../styledComponents/section"

import {
  StyledViewAllEventsButtonWrapper
} from "../styledComponents/eventArchive"
import { StyledButton, StyledLinkButton } from "../styledComponents/button"

import { TestimonialsSection, TestimonialsSectionHeader } from "../styledComponents/testimonials"
import {
  ClientCommentWrapper,
  ClientCommentList,
  ClientCommentListItem,
  ClientCommentArrows,
  ClientCommentArrowItem,
  ClientCommentListDot,
  ClientCommentListItemDot,

} from "../styledComponents/clientCompanyComments"

import { useSwipeable } from "react-swipeable"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)



const Testimonials = ({
  data,
  isLander,
  isInsideHero,
  showDots,
  showTestimonialsSectionHeader,
  showMoreTestimonialsButton,
  showMoreTestimonialsButtonLink,
  showMoreTestimonialsButtonText,
  showMoreTestimonialsInPage,
  isTestimonialsOpen,
  setIsTestimonialsOpen,
  toggleTestimonials
}) => {
  const [currentQuoteIndex, setCurrentQuote] = useState(0)
  const totalQuotes = data.wpPage.testimonials.blockquote.length
  const handlers = useSwipeable({
    onSwipedLeft: () => swipe(currentQuoteIndex, "left"),
    onSwipedRight: () => swipe(currentQuoteIndex, "right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  })

  const swipe = (currentQuoteIndex, direction) => {
    currentQuoteIndex = parseInt(currentQuoteIndex)
    if (currentQuoteIndex === 0) {
      return direction === "left"
        ? setCurrentQuote(currentQuoteIndex + 1)
        : setCurrentQuote(0)
    }

    if (currentQuoteIndex + 1 === data.wpPage.testimonials.blockquote.length) {
      return direction === "left"
        ? setCurrentQuote(currentQuoteIndex)
        : setCurrentQuote(currentQuoteIndex - 1)
    }

    if (currentQuoteIndex > 0) {
      return direction === "left"
        ? setCurrentQuote(currentQuoteIndex + 1)
        : setCurrentQuote(currentQuoteIndex - 1)
    }
  }

  return (
    <TestimonialsSection name="our-clients" noPadding id="testimonials">
      {showTestimonialsSectionHeader &&
        <TestimonialsSectionHeader>
          <Heading level={2}>
            {data.wpPage.testimonials.testimonialHeading}
          </Heading>
        </TestimonialsSectionHeader>
      }
      <ClientCommentWrapper>
        <ClientCommentList
          {...handlers}
          totalQuotes={parseInt(data.wpPage.testimonials.blockquote.length)}
          index={parseInt(currentQuoteIndex)}
        >
          {data.wpPage.testimonials.blockquote.map((quotes, index) => {
            return (
              <ClientCommentListItem isInFocus={parseInt(currentQuoteIndex) === index} key={`${index}-${quotes.citationName}`}   role={parseInt(currentQuoteIndex) !== index ? "presentation" : undefined}>
                <blockquote>{parse(quotes.quote)}</blockquote>
                <cite>
                  <b>{quotes.citationName}</b> {quotes.citeLocation}
                </cite>
              </ClientCommentListItem>
            )
          })}
        </ClientCommentList>
        {totalQuotes > '1' && showDots && (
          <ClientCommentListDot>
            {data.wpPage.testimonials.blockquote.map((quote, index) => {
              const citationName = quote.citationName.replace(/[,.\- ]/g, "-").toLowerCase()
              return (
                <ClientCommentListItemDot isInsideHero={isInsideHero} key={'dot-' + index}>
                  <label htmlFor={citationName}>
                    <input
                      aria-hidden="true"
                      type="radio"
                      id={citationName}
                      checked={parseInt(currentQuoteIndex) === index}
                      name="quote"
                      value={index}
                      onChange={e => setCurrentQuote(e.target.value)}
                    />
                  </label>
                </ClientCommentListItemDot>
              )
            })}
          </ClientCommentListDot>
        )}

        {totalQuotes > '1' && (
          <ClientCommentArrows>
            <ClientCommentArrowItem>
              <button aria-label="Show next testimonial" role="button" onClick={() => swipe(currentQuoteIndex, "right")} disabled={parseInt(currentQuoteIndex) === 0} >
                <svg
                  width="82"
                  height="82"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <circle
                      cx="41"
                      cy="41"
                      r="41"
                      transform="rotate(-180 41 41)"
                      fill={isInsideHero ? 'transparent' : '#fff'}
                    />
                    <path
                      d="M44.53 28c.368 0 .735.147 1.03.44a1.462 1.462 0 010 2.093L35.6 40.482l9.958 9.985a1.462 1.462 0 010 2.093 1.465 1.465 0 01-2.094 0L32.44 41.546A1.45 1.45 0 0132 40.518c0-.404.147-.77.44-1.028l11.025-11.013c.33-.33.698-.477 1.065-.477z"
                      fill={isInsideHero ? 'var(--white)' : '#3D3B37'}
                    />
                  </g>
                </svg>
              </button>
            </ClientCommentArrowItem>
            <ClientCommentArrowItem>
              <button aria-label="Show previous testimonial" role="button" onClick={() => swipe(currentQuoteIndex, "left")} disabled={parseInt(currentQuoteIndex + 1) === totalQuotes}  >
                <svg
                  width="82"
                  height="82"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <circle
                      cx="41"
                      cy="41"
                      r="41"
                      transform="rotate(-180 41 41)"
                      fill={isInsideHero ? 'transparent' : '#fff'}
                    />
                    <path
                      d="M37.47 53c-.368 0-.735-.147-1.03-.44a1.462 1.462 0 010-2.093l9.959-9.949-9.958-9.985a1.462 1.462 0 010-2.093 1.465 1.465 0 012.094 0L49.56 39.454c.294.293.441.66.441 1.028 0 .404-.147.77-.44 1.028L38.534 52.523c-.33.33-.698.477-1.065.477z"
                      fill={isInsideHero ? 'var(--white)' : '#3D3B37'}
                    />
                  </g>
                </svg>
              </button>
            </ClientCommentArrowItem>
          </ClientCommentArrows>
        )}
      </ClientCommentWrapper>
      {showMoreTestimonialsButton && showMoreTestimonialsButtonLink &&
        <StyledViewAllEventsButtonWrapper><StyledLinkButton to={showMoreTestimonialsButtonLink}>{showMoreTestimonialsButtonText}</StyledLinkButton></StyledViewAllEventsButtonWrapper>
      }

      {showMoreTestimonialsInPage &&

        !isTestimonialsOpen ?
        <StyledViewAllEventsButtonWrapper>
          <StyledButton
            onClick={() => toggleTestimonials(true)}>{showMoreTestimonialsButtonText}
          </StyledButton>
        </StyledViewAllEventsButtonWrapper>
        : <></>
      }
    </TestimonialsSection>
  )
}

export default Testimonials